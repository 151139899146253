import React, { useCallback, useState } from 'react';
import { TextField } from './TextField';
import { Button } from './Button';
import { Select } from './Select';
import { FieldGroup } from './FieldGroup';

const warehouseOptions = [
  { label: 'Dallas, TX', value: '1' },
  { label: 'Salt Lake City, UT', value: '2' },
  { label: 'Philadelphia, PA', value: '3' },
];

interface Variables {
  package: {
    packagingType: 'TYPE_00';
    dimensions: {
      height: number;
      length: number;
      width: number;
    };
    weight: number;
  };
  shipTo: {
    city?: string;
    postalCode: string;
    isResidential: boolean;
  };
  warehouseId: string;
}

interface FormProps {
  loading: boolean;
  onSubmit: (variables: Variables) => void;
}

export const Form: React.FC<FormProps> = ({ loading, onSubmit }) => {
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [warehouseId, setWarehouseId] = useState<string>('1');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [isResidential, setIsResidential] = useState(true);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      onSubmit({
        package: {
          dimensions: {
            height: +height,
            length: +length,
            width: +width,
          },
          packagingType: 'TYPE_00',
          weight: +weight,
        },
        shipTo: {
          city: city || undefined,
          postalCode,
          isResidential,
        },
        warehouseId,
      });
    },
    [postalCode, warehouseId, length, width, height, weight, isResidential, city]
  );

  return (
    <form className="max-w-xs grid grid-cols-3 gap-4" onSubmit={handleSubmit}>
      <h3 className="col-span-3 font-bold antialiased">Ship From</h3>
      <FieldGroup className="col-span-3" htmlFor="warehouseId" label="Warehouse">
        <Select
          id="warehouseId"
          placeholder="Select Warehouse"
          required
          value={warehouseId}
          onChange={e => setWarehouseId(e.target.value)}
        >
          {warehouseOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </FieldGroup>

      <h3 className="col-span-3 font-bold antialiased mt-4">Ship To</h3>

      <FieldGroup className="col-span-3" htmlFor="city" label="City">
        <TextField
          id="city"
          name="city"
          placeholder="City"
          type="text"
          value={city}
          onChange={e => setCity(e.target.value)}
        />
      </FieldGroup>

      <FieldGroup className="col-span-3" htmlFor="postal-code" label="Zip Code (Required)">
        <TextField
          id="postal-code"
          name="postalCode"
          placeholder="Zip Code"
          required
          type="text"
          value={postalCode}
          onChange={e => setPostalCode(e.target.value)}
        />
      </FieldGroup>

      <div className="col-span-3 flex items-center">
        <input
          checked={isResidential}
          className="mr-2"
          id="isResidential"
          name="isResidential"
          type="checkbox"
          onChange={e => setIsResidential(e.target.checked)}
        />
        <label htmlFor="isResidential"> Residential address</label>
      </div>

      <h3 className="col-span-3 font-bold antialiased mt-4">Package Details</h3>

      <FieldGroup htmlFor="length" label="Length">
        <TextField
          id="length"
          name="length"
          placeholder="Length"
          step=".001"
          type="number"
          value={length}
          onChange={e => setLength(e.target.value)}
        />
      </FieldGroup>

      <FieldGroup htmlFor="width" label="Width">
        <TextField
          id="width"
          name="width"
          placeholder="Width"
          step=".001"
          type="number"
          value={width}
          onChange={e => setWidth(e.target.value)}
        />
      </FieldGroup>

      <FieldGroup htmlFor="height" label="Height">
        <TextField
          id="height"
          name="height"
          placeholder="Height"
          step=".001"
          type="number"
          value={height}
          onChange={e => setHeight(e.target.value)}
        />
      </FieldGroup>

      <FieldGroup className="col-span-3" htmlFor="weight" label="Weight (Pounds) (Required)">
        <TextField
          id="weight"
          name="weight"
          placeholder="Weight"
          required
          step=".001"
          type="number"
          value={weight}
          onChange={e => setWeight(e.target.value)}
        />
      </FieldGroup>
      <Button className="col-start-1 col-span-3" loading={loading} type="submit">
        Get Rates
      </Button>
    </form>
  );
};
