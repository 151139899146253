import React from 'react';

interface TextFieldProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

export const TextField: React.FC<TextFieldProps> = ({ className, ...props }) => (
  <input
    className="rounded border-solid border-black border-2 border-opacity-75 leading-8 px-3 placeholder-black placeholder-opacity-50 focus:border-teal-500 focus:border-opacity-100 outline-none"
    {...props}
  />
);
