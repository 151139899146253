import React from 'react';

interface ButtonProps {
  loading?: boolean;
}

export const Button: React.FC<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & ButtonProps
> = ({ className, children, disabled, loading, ...props }) => (
  <button
    {...props}
    disabled={disabled || loading}
    className={`${className} bg-gray-900 rounded h-10 text-white hover:bg-gray-800 active:bg-gray-900 disabled:opacity-25 disabled:cursor-not-allowed transition duration-100`}
  >
    {loading ? 'Loading...' : children}
  </button>
);
