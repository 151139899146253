import React from 'react';

interface ResultsProps {
  rates: {
    serviceName: string;
    daysInTransit?: number | null;
    totalCharges: number;
  }[];
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const Results: React.FC<ResultsProps> = ({ rates }) => {
  return (
    <div className="flex flex-col">
      <table className="w-full text-left border">
        <thead>
          <tr>
            <th className="border-b px-4 py-2">Service Name</th>
            <th className="border-b px-4 py-2">Days In Transit</th>
            <th className="border-b px-4 py-2">Cost</th>
          </tr>
        </thead>
        <tbody>
          {rates.map(({ daysInTransit, serviceName, totalCharges }) => (
            <tr key={serviceName}>
              <td className="h-12 border-b px-4 text-black text-opacity-75">{serviceName}</td>
              <td className="h-12 border-b px-4 text-black text-opacity-75">{daysInTransit ?? 'N/A'}</td>
              <td className="h-12 border-b px-4 text-black text-opacity-75">{formatter.format(totalCharges)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <span className="text-black text-opacity-50 mt-4">
        Rates provided are estimates based on our current contract with UPS and are subject to change.
      </span>
    </div>
  );
};
