import React from 'react';
import styles from './Header.module.css';

export const Header: React.FC = () => (
  <header className={`${styles.header} bg-black text-white`}>
    <div className="items-center sm:flex justify-between max-w-4xl mx-auto py-4 px-4">
      <img src="/send-eats-logo.png" alt="Send Eats Logo" />
      <h1 className="font-light antialiased text-4xl">UPS Negotiated Rates</h1>
    </div>
  </header>
);
