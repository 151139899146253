import React from 'react';
import { Icon } from '@rmwc/icon';

export const ResultsErrorState = () => (
  <div className="grid place-items-center place-content-center self-center px-4 text-center">
    <Icon className="text-red-500" icon="warning" />
    <h3 className="text-red-500">An error occurred</h3>
    <p className="text-black opacity-50">
      Please check your shipping details and try again. If the error persists, please contact us.
    </p>
  </div>
);
