import React, { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Header } from '../components/Header';
import '@rmwc/icon/icon.css';
import '@rmwc/circular-progress/circular-progress.css';
import './index.css';
import { Form } from '../components/Form';
import { Results } from '../components/Results';
import gql from 'graphql-tag';
import { ResultsEmptyState } from '../components/ResultsEmptyState';
import { ResultsLoadingState } from '../components/ResultsLoadingState';
import { ResultsErrorState } from '../components/ResultsErrorState';

const RATES_QUERY = gql`
  query GetRates($input: GetRatesInput!) {
    getRates(input: $input) {
      serviceName
      daysInTransit
      totalCharges
    }
  }
`;

export default function Home() {
  const [query, { data, loading, error }] = useLazyQuery(RATES_QUERY);

  const onSubmit = useCallback(
    async input => {
      try {
        const result = await query({ variables: { input } });
        console.log('Success!', { result });
      } catch (err) {
        console.error(err);
      }
    },
    [query]
  );

  return (
    <div className="page">
      <Header />
      <div className="grid max-w-4xl p-4 mx-auto md:grid-cols-main justify-items-center gap-x-8 gap-y-8 items-start px-4">
        <Form loading={loading} onSubmit={onSubmit} />

        {loading ? (
          <ResultsLoadingState />
        ) : error ? (
          <ResultsErrorState />
        ) : data?.getRates?.length ? (
          <Results rates={data.getRates} />
        ) : (
          <ResultsEmptyState />
        )}
      </div>
    </div>
  );
}
