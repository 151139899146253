import React from 'react';

interface FieldGroupProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  htmlFor?: string;
  label: string;
}

export const FieldGroup: React.FC<FieldGroupProps> = ({ children, className, htmlFor, label }) => (
  <div className={`flex flex-col ${className}`}>
    <label className="text-sm" htmlFor={htmlFor}>
      {label}
    </label>
    {children}
  </div>
);
