import React from 'react';
import { Icon } from '@rmwc/icon';

export const ResultsEmptyState: React.FC = () => (
  <div className="grid place-items-center place-content-center self-center text-center">
    <Icon icon="local_shipping" className="text-teal-500" style={{fontSize: '3rem !important'}} />
    <span>
      Fill out the <b>Ship To</b> and <b>Package Details</b> to get rates.
    </span>
  </div>
);
